<template>
  <h4>Edit</h4>
  <hr class="mdb-my-4" />

  <h5>href</h5>
  <MDBInput v-model="input" label="href" @update:modelValue="updateValue" />
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref("");

    const input = ref("");

    const updateValue = (value) => {
      blockConfig.value[configName.value] = value;
      element.value.href = value;
      updateConfig.value();
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          input.value = "";
        }
      },
    );

    onMounted(() => {
      configName.value = element.value.dataset["builderHref"];
      input.value = blockConfig.value[configName.value];
    });

    return {
      input,
      updateValue,
      configName,
      blockConfig,
    };
  },
};
</script>
