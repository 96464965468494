<template>
  <h4>Edit background</h4>
  <hr class="mdb-my-4" />

  <div v-for="(attr, key) in iteratedValues" :key="key">
    <div class="mdb-pb-4">
      <MDBInput
        v-model="inputs[key]"
        :label="`Image ${key === 'image' ? 'src' : key}`"
        @blur="updateValue(key)"
        @keydown.enter="updateValue(key)"
      />
    </div>
  </div>

  <div class="d-flex align-items-center mb-4">
    <input
      type="color"
      class="form-control form-control-color mdb-h-100"
      id="formColor"
      title="Choose your color"
      colorpick-eyedropper-active="true"
      style="max-width: 100%; padding: 0px; border: none"
      v-model="inputs.color"
      @update:modelValue="updateValue('color')"
    />
    <MDBBtn
      size="sm"
      color="primary"
      class="mdb-m-1"
      block
      @click="
        () => {
          inputs.color = '#ffffff';
          updateValue('color');
        }
      "
    >
      Reset
    </MDBBtn>
  </div>

  <hr class="mdb-mt-0 mdb-text-dark" />

  <SearchImages :updateFn="updateImage" />
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import MDBBtn from "@/components/mdb/MDBBtn";
import SearchImages from "@/components/pickers/SearchImages";
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRaw,
  watch,
} from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput, MDBBtn, SearchImages },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref(element.value.dataset["builderName"]);

    const iteratedValues = computed(() => {
      const newValues = {};
      for (let key in toRaw(blockConfig.value[configName.value])) {
        if (key !== "color") {
          newValues[key] = blockConfig.value[configName.value][key];
        }
      }
      return newValues;
    });

    const isContentLoaded = ref(false);

    const inputs = reactive({
      image: "",
      size: "",
      position: "",
      color: "#ffffff",
    });

    const updateImage = (image) => {
      inputs.image = image;
      updateValue("image");
    };

    const updateValue = (type) => {
      blockConfig.value[configName.value][type] = inputs[type];
      if (type === "image") {
        const url = `url(${inputs[type]})`;
        element.value.style[`background-${type}`] = url;
        blockConfig.value[configName.value][type] = url;

        element.value.style[`background-color`] = "transparent";
        delete blockConfig.value[configName.value].color;
      }
      if (type === "color") {
        element.value.style[`background-${type}`] = inputs[type];
        blockConfig.value[configName.value][type] = inputs[type];

        element.value.style[`background-image`] = null;
        blockConfig.value[configName.value].image = null;
      } else {
        element.value.style[`background-${type}`] = inputs[type];
        blockConfig.value[configName.value][type] = inputs[type];
      }
      updateConfig.value();
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          inputs.value = {
            image: "",
            size: "",
            position: "",
            color: "",
          };
        } else {
          nextTick(() => {
            isContentLoaded.value = true;
          });
        }
      },
    );

    onMounted(() => {
      for (const key in blockConfig.value[configName.value]) {
        inputs[key] = blockConfig.value[configName.value][key];
      }

      isContentLoaded.value = true;
    });

    return {
      iteratedValues,
      inputs,
      updateValue,
      isContentLoaded,
      configName,
      blockConfig,
      updateImage,
    };
  },
};
</script>
