import colors from "./colors.json";

export const appendConfig = (root, blockConfig) => {
  root.querySelectorAll(".form-outline").forEach((formOutline) => {
    if (!window.mdb) {
      return;
    }

    new window.mdb.Input(formOutline).init();
    new window.mdb.Input(formOutline).update();
  });

  const textNodes = root.querySelectorAll("[data-builder-edit='text']");
  textNodes.forEach((textNode) => {
    if (textNode.dataset["builderName"]) {
      textNode.innerText = blockConfig.value[textNode.dataset["builderName"]];
    }
  });

  const hrefNodes = root.querySelectorAll("[data-builder-href]");
  hrefNodes.forEach(
    (hrefNode) =>
      (hrefNode.href = blockConfig.value[hrefNode.dataset["builderHref"]]),
  );

  const iconNodes = root.querySelectorAll("[data-builder-edit='icon']");
  iconNodes.forEach(
    (iconNode) =>
      (iconNode.classList = blockConfig.value[iconNode.dataset["builderName"]]),
  );

  const imageNodes = root.querySelectorAll("[data-builder-edit='image']");
  imageNodes.forEach((imageNode) => {
    imageNode.src = blockConfig.value[imageNode.dataset["builderName"]].src;
    imageNode.alt = blockConfig.value[imageNode.dataset["builderName"]].alt;
    if (imageNode.classList.contains("rounded-circle")) {
      imageNode.style.height = imageNode.style.width;
    }
  });

  const backgroundNodes = root.querySelectorAll(
    "[data-builder-edit='background']",
  );
  backgroundNodes.forEach((backgroundNode) => {
    const configName = backgroundNode.dataset["builderName"];

    for (const key in blockConfig.value[configName]) {
      if (key === "image") {
        backgroundNode.style[
          `background-${key}`
        ] = `url(${blockConfig.value[configName][key]})`;
      } else {
        backgroundNode.style[`background-${key}`] =
          blockConfig.value[configName][key];
      }
    }
  });

  const buttonNodes = root.querySelectorAll("[data-builder-edit='button']");
  buttonNodes.forEach((buttonNode) => {
    let colorMatch = [...buttonNode.classList].find((className) => {
      return colors.filter((color) => className.includes(color)).length;
    });

    if (colorMatch && colorMatch.startsWith("btn")) {
      buttonNode.className = buttonNode.className.replace(
        colorMatch,
        ` btn-${blockConfig.value[buttonNode.dataset["builderName"]].color}`,
      );
    } else if (blockConfig.value[buttonNode.dataset["builderName"]].color) {
      buttonNode.classList.add(
        `btn-${blockConfig.value[buttonNode.dataset["builderName"]].color}`,
      );
    }

    buttonNode.innerText =
      blockConfig.value[buttonNode.dataset["builderName"]].text;

    if (blockConfig.value[buttonNode.dataset["builderName"]].icon) {
      const icon = document.createElement("i");
      icon.classList.add(
        ...blockConfig.value[buttonNode.dataset["builderName"]].icon.split(" "),
      );
      icon.classList.add(...["p-1", "ps-2"]);

      buttonNode.appendChild(icon);
    }
  });
};
