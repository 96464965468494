<template>
  <nav
    class="
      mdb-navbar
      mdb-navbar-expand-lg
      mdb-navbar-light
      mdb-bg-white
      mdb-fixed-top
    "
  >
    <!-- Container wrapper -->
    <div class="mdb-container-fluid">
      <div class="mdb-d-flex mdb-align-items-center">
        <router-link to="/">
          <MDBBtn color="primary" class="mdb-me-2">
            <i class="fas fa-home"></i>
          </MDBBtn>
        </router-link>
        <MDBBtn
          color="primary"
          size="lg"
          floating
          style="position: fixed; left: 25px; top: 85px; z-index: 1030"
          aria-controls="#sidenav"
          aria-haspopup="true"
          @click="sidenav = !sidenav"
        >
          <i :class="['fas', 'fa-cogs']"></i>
        </MDBBtn>
        <MDBBtn
          color="primary"
          class="mdb-me-2"
          @click="save"
          :disabled="userComponents.length === 0"
        >
          <i :class="['fas', 'fa-save', !smallButtons && 'mdb-me-2']"></i
          ><span v-if="!smallButtons">Save</span>
        </MDBBtn>
        <MDBBtn
          color="primary"
          class="mdb-me-2"
          :disabled="userComponents.length === 0"
          @click="publish"
        >
          <i :class="['fas', 'fa-upload', !smallButtons && 'mdb-me-2']"></i
          ><span v-if="!smallButtons">Publish</span>
        </MDBBtn>
        <MDBSelect
          v-model:options="mdbVersions"
          label="MDB version"
          :style="{ width: smallButtons ? '80px' : '120px' }"
        />
      </div>

      <div class="mdb-d-flex mdb-align-items-center">
        <MDBDropdown
          align="end"
          v-model="changeViewDropdown"
          class="mdb-d-none mdb-d-md-inline-block"
        >
          <MDBDropdownToggle
            @click="changeViewDropdown = !changeViewDropdown"
            color="link"
            class="mdb-text-capitalize mdb-hidden-arrow mdb-text-dark"
          >
            <i class="fas fa-desktop mdb-me-2"></i>Change view
          </MDBDropdownToggle>
          <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
            <MDBDropdownItem
              href="#"
              classes="mdb-text-dark"
              @click.prevent="setDisplay('mobile')"
              ><i class="fas fa-mobile-alt mdb-me-2"></i>Mobile</MDBDropdownItem
            >
            <MDBDropdownItem
              href="#"
              @click.prevent="setDisplay('tablet')"
              classes="mdb-text-dark"
              ><i class="fas fa-tablet-alt mdb-me-2"></i>Tablet</MDBDropdownItem
            >
            <MDBDropdownItem
              href="#"
              @click.prevent="setDisplay('desktop')"
              classes="mdb-text-dark"
              ><i class="fas fa-desktop mdb-me-2"></i>Desktop</MDBDropdownItem
            >
            <MDBDropdownItem
              href="#"
              @click.prevent="setFullscreen()"
              classes="mdb-text-dark"
              ><i class="fas fa-arrows-alt mdb-me-2"></i
              >Fullscreen</MDBDropdownItem
            >
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBModal
          id="saveModal"
          tabindex="-1"
          labelledby="saveModalLabel"
          v-model="saveModal"
        >
          <MDBModalHeader>
            <MDBModalTitle id="saveModalLabel" class="mdb-text-dark"
              >Choose project name</MDBModalTitle
            >
          </MDBModalHeader>
          <MDBModalBody>
            <MDBInput class="mdb-my-3" label="Name" v-model="projectName" />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" @click="saveModal = false">Close</MDBBtn>
            <MDBBtn color="primary" @click="saveProject">Save</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <LoginPanel />
      </div>
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->

  <MDBSideNav
    v-model="sidenav"
    id="sidenav"
    classes="mdb-px-3 mdb-py-4"
    :sidenavWidth="400"
    :closeOnEsc="true"
  >
    <h4 class="mdb-text-dark">Settings</h4>
    <hr class="mdb-mt-2 mdb-mb-5 mdb-text-dark" />

    <section class="mdb-mb-5">
      <h5 class="mdb-text-dark mdb-mt-3">Project name</h5>

      <MDBInput
        v-model="newProjectName"
        inputGroup
        :formOutline="false"
        wrapperClass="mb-3"
      >
        <MDBBtn color="primary" @click="editProjectName">Change</MDBBtn>
      </MDBInput>
    </section>

    <h5 class="mdb-text-dark mdb-mt-3">Theme</h5>

    <div class="mdb-row">
      <div class="mdb-col-2 mdb-d-flex mdb-align-items-center">
        <span class="mdb-text-dark">Light:</span>
      </div>
      <div class="mdb-col-10">
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #1266f1; opacity: 0.6"
          active
          @click="changeTheme('light.1')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #9c27b0; opacity: 0.6"
          @click="changeTheme('light.2')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #b04e27; opacity: 0.6"
          @click="changeTheme('light.3')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #27b037; opacity: 0.6"
          @click="changeTheme('light.4')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #8e6d00; opacity: 0.6"
          @click="changeTheme('light.5')"
        ></MDBBtn>
      </div>
    </div>

    <div class="mdb-row mdb-mt-1">
      <div class="mdb-col-2 mdb-d-flex mdb-align-items-center">
        <span class="mdb-text-dark">Dark:</span>
      </div>
      <div class="mdb-col-10">
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #1266f1"
          @click="changeTheme('dark.1')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #9c27b0"
          @click="changeTheme('dark.2')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #b04e27"
          @click="changeTheme('dark.3')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #27b037"
          @click="changeTheme('dark.4')"
        ></MDBBtn>
        <MDBBtn
          size="sm"
          color="link"
          floating
          class="mdb-m-1 mdb-border"
          style="background-color: #8e6d00"
          @click="changeTheme('dark.5')"
        ></MDBBtn>
      </div>
    </div>

    <section v-if="projectId">
      <hr class="mdb-my-5 mdb-text-dark" />

      <h5 class="mdb-text-dark">Delete project</h5>
      <p class="mdb-text-dark">Deleted projects cannot be restored!</p>
      <MDBPopconfirm
        @click.stop
        @confirm="deleteProject"
        confirmText="Delete"
        class="mdb-btn-danger"
        ><MDBIcon icon="trash" class="mdb-pe-2" />Delete</MDBPopconfirm
      >
    </section>

    <!-- <h6 class="mdb-text-dark mdb-mt-4"><strong>Preview container</strong>:</h6>

    <p class="mdb-text-dark">Size:</p>
    <MDBBtn
      v-for="(container, key) in containers"
      :key="key"
      size="sm"
      color="primary"
      class="mdb-m-1"
      @click="setPreviewData('container', container.value)"
      >{{ container.name }}</MDBBtn
    >

    <p class="mdb-text-dark mdb-mt-3">Padding:</p>
    <MDBRange
      v-model="paddingRange"
      :min="0"
      :max="5"
      @update:modelValue="(e) => setPreviewData('padding', e)"
    /> -->
    <!-- <p class="mdb-text-dark">Margin:</p>
      <MDBRange
        v-model="marginRange"
        :min="0"
        :max="5"
        @update:modelValue="(e) => setPreviewData('margin', e)"
      /> -->
    <!-- 
      <p class="mdb-text-dark">Color:</p>
      <div class="d-flex align-items-center mb-4">
        <input
          type="color"
          class="form-control form-control-color mdb-h-100"
          id="formColor"
          title="Choose your color"
          colorpick-eyedropper-active="true"
          style="max-width: 100%; padding: 0px; border: none"
          @input="setColor"
        />
        <MDBBtn
          size="sm"
          color="primary"
          class="mdb-m-1"
          block
          @click="setPreviewData('color', null)"
        >
          Reset
        </MDBBtn>
      </div> -->
  </MDBSideNav>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import MDBDropdown from "@/components/mdb/MDBDropdown";
import MDBDropdownToggle from "@/components/mdb/MDBDropdownToggle";
import MDBDropdownMenu from "@/components/mdb/MDBDropdownMenu";
import MDBDropdownItem from "@/components/mdb/MDBDropdownItem";
import MDBSideNav from "@/components/mdb/MDBSideNav";
import MDBSelect from "@/components/mdb/MDBSelect";
import MDBModal from "@/components/mdb/MDBModal";
import MDBModalBody from "@/components/mdb/MDBModalBody";
import MDBModalFooter from "@/components/mdb/MDBModalFooter";
import MDBModalHeader from "@/components/mdb/MDBModalHeader";
import MDBModalTitle from "@/components/mdb/MDBModalTitle";
import MDBInput from "@/components/mdb/MDBInput";
import MDBIcon from "@/components/mdb/MDBIcon";
import MDBPopconfirm from "@/components/mdb/MDBPopconfirm";
import LoginPanel from "@/components/LoginPanel";
import { ref, computed, watch, nextTick, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { on, off } from "@/utils/MDBEventHandlers";

export default {
  name: "HomeNavbar",
  components: {
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBSideNav,
    MDBSelect,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBInput,
    MDBIcon,
    MDBPopconfirm,
    LoginPanel,
  },
  emits: ["loading-versions"],
  setup(_, { emit }) {
    const store = useStore();

    const changeViewDropdown = ref(false);
    const sidenav = ref(false);
    const theme = computed(() => store.getters.theme);
    const projectIdStore = computed(() => store.getters.projectId);
    const projectId = ref(projectIdStore.value);
    const userComponents = computed(() => store.getters.userComponents);

    const mdbVersionsStore = computed(() => store.getters.mdbVersions);
    const mdbVersion = computed(() => store.getters.mdbVersion);
    const mdbVersions = ref([]);

    const saveModal = ref(false);
    const projectNameStore = computed(() => store.getters.projectName);
    const projectName = ref(projectNameStore.value);

    watch(
      () => projectIdStore.value,
      (value) => {
        projectId.value = value;
      },
      { deep: true },
    );

    watch(
      () => projectNameStore.value,
      (value) => {
        projectName.value = value;
      },
      { deep: true },
    );

    store.dispatch("getAllMDBVersions");

    watch(
      () => projectName.value,
      (name) => {
        newProjectName.value = name;
        store.dispatch("setProjectName", name);
      },
    );

    watch(
      () => mdbVersionsStore.value,
      (versions) => {
        versions.forEach((version) => {
          mdbVersions.value.push({
            text: version.version,
            value: version.id,
            selected: version.selected,
          });
        });
      },
      { deep: true },
    );

    watch(
      () => mdbVersions.value,
      (versions) => {
        const selectedVersion = versions.find((version) => version.selected);
        if (selectedVersion) {
          store.dispatch("setMDBVersion", [
            selectedVersion.value,
            selectedVersion.text,
          ]);
          loadTheme(theme.value);
        }
      },
      { deep: true },
    );

    const loadTheme = (theme) => {
      const oldLink = document.querySelector("link[title='mdb']");
      if (oldLink) {
        oldLink.remove();
      }

      emit("loading-versions", true);

      const link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("title", "mdb");
      link.setAttribute(
        "href",
        `${process.env.VUE_APP_MDB_UI_KIT}/MDB5-STANDARD-UI-KIT-Free-${mdbVersion.value}/css/themes/mdb.${theme}.min.css`,
      );
      document.getElementsByTagName("head")[0].appendChild(link);
      link.addEventListener("load", () => {
        nextTick(() => {
          emit("loading-versions", false);
        });
      });
    };

    const changeTheme = (theme) => {
      loadTheme(theme);
      store.dispatch("changeTheme", theme);
    };

    const display = computed(() => store.getters.display);
    const setDisplay = (display) => {
      store.dispatch("setDisplay", display);
      changeViewDropdown.value = false;
    };

    const setFullscreen = () => {
      if (!changeViewDropdown.value) {
        return;
      }
      store.dispatch("setFullscreen");
      changeViewDropdown.value = false;
    };

    const save = () => {
      if (projectId.value === null) {
        saveModal.value = true;
      } else {
        saveProject();
      }
    };

    const saveProject = () => {
      store.dispatch("save");
      saveModal.value = false;
    };

    const deleteProject = () => {
      store.dispatch("deleteProject");
    };

    // const containers = [
    //   { name: "default", value: "container" },
    //   { name: "sm", value: "container-sm" },
    //   { name: "md", value: "container-md" },
    //   { name: "lg", value: "container-lg" },
    //   { name: "xl", value: "container-xl" },
    //   { name: "xxl", value: "container-xxl" },
    //   { name: "100%", value: "" },
    // ];

    // const paddingRange = ref(store.getters.projectConfig.padding);
    // const marginRange = ref(store.getters.projectConfig.margin);
    // const colorInput = ref(store.getters.projectConfig.color);

    // const setPreviewData = (property, value) => {
    //   store.dispatch("setPreviewData", { value, property });
    // };

    // const setColor = (e) => {
    //   setPreviewData("color", e.target.value);
    // };

    const newProjectName = ref(projectName.value);
    const editProjectName = () => {
      store.dispatch("editProjectName", newProjectName.value);
    };

    const windowWidth = ref(window.innerWidth);
    const smallButtons = ref(false);

    const checkButtons = () => {
      windowWidth.value = window.innerWidth;

      if (windowWidth.value <= 850) {
        smallButtons.value = true;
      } else {
        smallButtons.value = false;
      }
    };

    onMounted(() => {
      checkButtons();
      on(window, "resize", checkButtons);
    });

    onUnmounted(() => {
      off(window, "resize", checkButtons);
    });

    const publish = () => {
      store.dispatch("publish");
    };

    return {
      display,
      setDisplay,
      setFullscreen,
      changeViewDropdown,
      sidenav,
      changeTheme,
      mdbVersions,
      save,
      saveModal,
      saveProject,
      projectName,
      userComponents,
      newProjectName,
      editProjectName,
      projectId,
      deleteProject,
      smallButtons,
      publish,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
