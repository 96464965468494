import icons from "../../utils/fa.new.json";

export function searchIcons(query) {
  let iconsArray;

  if (query.length < 1) {
    iconsArray = [];
    return;
  }
  const foundIcons = icons.dataArray.filter(
    (icon) =>
      icon.id.toLowerCase().includes(query.toLowerCase()) ||
      icon.search.terms?.filter((term) => term.includes(query.toLowerCase()))
        .length > 0,
  );

  iconsArray = foundIcons.reduce((acc, cur) => {
    if (Array.isArray(cur.attributes.prefix)) {
      cur.attributes.prefix.map((prefix) =>
        acc.push(`${prefix} ${cur.type}-${cur.id}`),
      );
    } else {
      acc.push(`${cur.attributes.prefix} ${cur.type}-${cur.id}`);
    }
    return acc;
  }, []);

  return iconsArray;
}

export function removeIconClasses(node) {
  const classesToRemove = node.className.split(" ").splice(0, 2).join(" ");
  const regx = new RegExp("\\b" + classesToRemove + "[^ ]*[ ]?\\b", "g");
  node.className = node.className.replace(regx, "");
  return {
    node,
  };
}
