<template>
  <MDBSideNav
    v-model="isActive"
    right
    :sidenavWidth="400"
    :closeOnEsc="true"
    id="picker-editor"
    :classes="className"
  >
    <div class="mdb-picker-content mdb-pb-2 mdb-h-100">
      <component :is="PickerContent" v-model="isActive" />
    </div>
  </MDBSideNav>
</template>

<script>
import MDBSideNav from "@/components/mdb/MDBSideNav";
import IconContent from "./IconContent.vue";
import ButtonContent from "./ButtonContent.vue";
import ImageContent from "./ImageContent.vue";
import BackgroundContent from "./BackgroundContent.vue";
import TextContent from "./TextContent.vue";
import SelectContent from "./SelectContent.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    MDBSideNav,
    IconContent,
    ButtonContent,
    ImageContent,
    BackgroundContent,
    TextContent,
  },
  setup() {
    const store = useStore();
    const sidenav = computed(() => store.getters.picker);
    const isActive = ref(sidenav.value !== null);

    const PickerContent = computed(() => {
      switch (sidenav.value) {
        case "icon":
          return IconContent;
        case "button":
          return ButtonContent;
        case "image":
          return ImageContent;
        case "text":
          return TextContent;
        case "background":
          return BackgroundContent;
        case "select":
          return SelectContent;
        default:
          return null;
      }
    });

    const className = computed(() => {
      return ["mdb-px-3", "mdb-py-4", "mdb-sidenav-picker"];
    });

    watch(
      () => sidenav.value,
      (cur) => {
        isActive.value = cur !== null;
      },
    );

    watch(
      () => isActive.value,
      (cur) => {
        if (!cur && sidenav.value) {
          store.dispatch("setPicker", null);
        }
      },
    );

    return {
      PickerContent,
      sidenav,
      className,
      isActive,
    };
  },
};
</script>
