<template>
  <nav
    class="
      mdb-navbar
      mdb-navbar-expand-lg
      mdb-navbar-light
      mdb-bg-white
      mdb-fixed-bottom
    "
  >
    <div
      class="
        mdb-container-fluid
        mdb-justify-content-center
        mdb-justify-content-lg-between
      "
    >
      <!-- Left elements -->
      <div class="mdb-d-none mdb-d-lg-flex mdb-align-items-center">
        <MDBBtn
          color="dark"
          class="mdb-me-2"
          @click="downloadProject"
          :disabled="userComponents.length === 0"
        >
          <i class="fas fa-download mdb-me-2"></i>Download
        </MDBBtn>
        <a
          v-if="projectUrl"
          :href="projectUrl"
          target="_blank"
          class="mdb-mx-2 mdb-small mdb-text-primary"
          >{{ projectUrl }}</a
        >
        <!-- <MDBBtn color="dark" class="mdb-me-2">
          <i class="fas fa-code-branch mdb-me-2"></i>Fork
        </MDBBtn>
        <MDBBtn color="dark" class="mdb-me-2">
          <i class="fab fa-git mdb-me-2"></i>Create repo
        </MDBBtn> -->
      </div>
      <!-- Left elements -->

      <!-- Right elements -->
      <div class="mdb-d-flex">
        <!-- <MDBBtn
          color="link"
          class="mdb-text-capitalize mdb-hidden-arrow mdb-text-dark"
        >
          Share<i class="fas fa-share-alt mdb-ms-2"></i>
        </MDBBtn>
        <MDBBtn
          color="link"
          class="mdb-text-capitalize mdb-hidden-arrow mdb-text-dark"
        >
          Embed<i class="fas fa-image mdb-ms-2"></i>
        </MDBBtn> -->
        <MDBBtn
          color="link"
          class="mdb-text-capitalize mdb-hidden-arrow mdb-text-dark"
          tag="a"
          href="https://mdbootstrap.com/support/"
          target="_blank"
        >
          Help<i class="fas fa-question-circle mdb-ms-2"></i>
        </MDBBtn>
      </div>
      <!-- Right elements -->
    </div>
  </nav>
</template>

<script>
import MDBBtn from "@/components/mdb/MDBBtn";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "EditorFooter",
  components: {
    MDBBtn,
  },
  setup() {
    const store = useStore();
    const userComponents = computed(() => store.getters.userComponents);
    const projectUrl = computed(() => store.getters.projectUrl);

    const downloadProject = () => {
      store.dispatch("downloadProject");
    };

    return {
      downloadProject,
      userComponents,
      projectUrl,
    };
  },
};
</script>
