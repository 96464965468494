<template>
  <div class="mdb-editor">
    <EditorNavbar @loadingVersions="setLoadingVersions" />
    <main style="padding-top: 58px; padding-bottom: 58px">
      <div class="mdb-container-fluid mdb-h-100 mdb-py-5 mdb-px-5">
        <div class="mdb-row mdb-gx-md-5 mdb-h-100">
          <div
            :class="[
              fullscreen ? 'mdb-col-lg-12' : 'mdb-col-lg-8',
              'mdb-h-100',
            ]"
            ref="fullscreenRef"
          >
            <div
              class="
                mdb-d-flex mdb-justify-content-center mdb-align-items-center
              "
              style="height: 100px"
              v-if="!isDataLoaded"
            >
              <MDBSpinner />
            </div>
            <MDBScrollbar
              suppressScrollX
              v-else
              class="mdb-rounded-3 mdb-position-relative mdb-h-100"
              :style="{ backgroundColor: previewBgColor }"
              :components="userComponents"
            >
              <Preview />
            </MDBScrollbar>
          </div>

          <div v-show="!fullscreen" class="mdb-col-lg-4 mdb-h-100">
            <div
              class="
                mdb-d-flex mdb-justify-content-center mdb-align-items-center
              "
              style="height: 100px"
              v-if="!isDataLoaded"
            >
              <MDBSpinner />
            </div>
            <MDBScrollbar
              suppressScrollX
              v-else
              class="mdb-rounded-3 mdb-position-relative mdb-bg-white mdb-h-100"
              id="mdb-blocks-list"
            >
              <List />
            </MDBScrollbar>
          </div>
        </div>
      </div>
    </main>
    <EditorFooter />
    <Picker />
  </div>
</template>

<script>
// @ is an alias to /src
import EditorNavbar from "@/components/EditorNavbar.vue";
import EditorFooter from "@/components/EditorFooter.vue";
import Picker from "@/components/pickers/Picker.vue";
import MDBScrollbar from "@/components/mdb/MDBScrollbar";
import MDBSpinner from "@/components/mdb/MDBSpinner";

import Preview from "@/components/Preview.vue";
import List from "@/components/List.vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { computed, onMounted, onUnmounted, watch } from "@vue/runtime-core";

export default {
  name: "Home",
  components: {
    EditorNavbar,
    EditorFooter,
    Picker,
    Preview,
    List,
    MDBScrollbar,
    MDBSpinner,
  },
  setup() {
    const store = useStore();

    const fullscreenRef = ref(null);
    const fullscreen = computed(() => store.getters.fullscreen);

    const userComponents = computed(() => store.getters.userComponents);
    const projectConfig = computed(() => store.getters.projectConfig);

    const previewBgColor = computed(() => {
      if (projectConfig.value.color) {
        return projectConfig.value.color;
      }
      if (store.getters.theme.includes("dark")) {
        return "#303030";
      }
      return "#fff";
    });

    const isFullscreen = () => {
      return (
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
      );
    };

    watch(
      () => fullscreen.value,
      (cur) => {
        if (cur) {
          if (fullscreenRef.value?.requestFullscreen) {
            fullscreenRef.value.requestFullscreen();
          }
        } else {
          if (document.exitFullscreen && isFullscreen()) {
            document.exitFullscreen();
          }
        }
      },
    );

    const handleFullScreenChange = () => {
      if (isFullscreen() && !fullscreen.value) {
        store.dispatch("setFullscreen", true);
      } else if (!isFullscreen() && fullscreen.value) {
        if (fullscreen.value) {
          store.dispatch("setFullscreen", false);
        }
      }
    };

    const isDataLoaded = ref(false);
    const loadingTheme = computed(() => store.getters.loadingTheme);

    const setLoadingVersions = (e) => {
      if (e) {
        isDataLoaded.value = false;
      } else {
        isDataLoaded.value = true;
      }
    };

    store.dispatch("getComponents");

    onMounted(() => {
      window.addEventListener("fullscreenchange", handleFullScreenChange);
      store.dispatch("auth");
    });

    onUnmounted(() => {
      window.removeEventListener("fullscreenchange", handleFullScreenChange);
    });

    return {
      userComponents,
      fullscreen,
      fullscreenRef,
      isDataLoaded,
      loadingTheme,
      previewBgColor,
      setLoadingVersions,
    };
  },
};
</script>
