<template>
  <div class="mdb-pb-3 mdb-pt-2">
    <MDBInput
      label="Search images"
      v-model="query"
      @update:modelValue="changeQuery"
      :formText="error ? `Ups! No images found for ${query}` : ''"
    />
  </div>
  <MDBSideNavMenu scrollContainer v-if="isContentLoaded">
    <div class="mdb-container mdb-container-fluid">
      <div class="mdb-row">
        <div class="mdb-masonry">
          <figure
            class="mdb-picker__img-container"
            v-for="(img, i) in images"
            :key="i"
            @click="updateFn(img.regular)"
          >
            <img
              :src="img.thumb"
              class="mdb-img-fluid mdb-shadow-2-strong mdb-rounded mdb-w-100"
              alt=""
            />
            <div class="mdb-picker__img-caption">
              <a rel="nofollow" :href="img.portfolio" target="_blank">{{
                img.author
              }}</a>
            </div>
          </figure>
        </div>
      </div>
    </div>
  </MDBSideNavMenu>
  <p class="mdb-text-muted mdb-d-flex justify-content-end">
    <small
      >Photos by
      <a rel="nofollow" href="https://unsplash.com/" target="_blank"
        >Unsplash</a
      ></small
    >
  </p>
</template>

<script>
import MDBSideNavMenu from "@/components/mdb/MDBSideNavMenu.vue";
import MDBInput from "@/components/mdb/MDBInput.vue";
import axios from "axios";
import { onMounted, ref } from "vue";

export default {
  components: {
    MDBSideNavMenu,
    MDBInput,
  },
  props: {
    updateFn: Function,
  },
  setup() {
    const images = ref([]);
    const query = ref("modern");
    const error = ref(false);
    const isContentLoaded = ref(false);

    const loadImages = () => {
      axios
        .get(
          `https://api.unsplash.com/search/photos?client_id=${process.env.VUE_APP_IMG_KEY}`,
          { params: { query: query.value } },
        )
        .then((resp) => {
          error.value = false;
          images.value = resp.data.results.map((img) => {
            const author = `${img.user.first_name} ${img.user.last_name}`;
            const portfolio = img.user.links.html;
            const download = img.links.download_location;

            return { ...img.urls, author, portfolio, download };
          });
        })
        .catch((err) => {
          error.value = err;
        });

      isContentLoaded.value = true;
    };
    const changeQuery = (e) => {
      query.value = e;
      if (query.value.length > 3) {
        loadImages();
      }
    };

    onMounted(() => {
      loadImages();
    });

    return {
      images,
      query,
      error,
      changeQuery,
      isContentLoaded,
    };
  },
};
</script>
