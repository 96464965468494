<template>
  <div id="preview" class="preview">
    <div v-show="!isDesktop">
      <iframe
        id="fileFrame"
        src="about:blank"
        ref="iframeRef"
        :style="iframeStyle"
        class="mdb-d-flex mdb-justify-content-center mdb-align-items-center"
      ></iframe>
    </div>
    <div v-show="isDesktop">
      <draggable
        v-model="components"
        item-key="timestamp"
        @end="handleDragChange"
        tag="transition-group"
        :component-data="{ name: 'mdb-flip-list', type: 'transition' }"
      >
        <template #item="{ element }">
          <div
            style="position: relative"
            @mouseenter="showControls = element.order"
            @mouseleave="showControls = -1"
          >
            <div
              v-if="showControls === element.order"
              style="z-index: 2; position: absolute; top: 10px; right: 30px"
              class="mdb-row mdb-d-flex"
            >
              <div class="mdb-col-3 mdb-p-0 mdb-ps-1">
                <MDBBtn
                  class="mdb-mt-0"
                  size="sm"
                  color="primary"
                  v-if="element.config.container === 'none'"
                  @click="changeComponentSize(element.order, 'container')"
                >
                  <i
                    class="fas fa-compress-alt"
                    style="transform: rotate(45deg)"
                  ></i>
                </MDBBtn>
                <MDBBtn
                  class="mdb-mt-0"
                  size="sm"
                  color="primary"
                  v-else
                  @click="changeComponentSize(element.order, 'none')"
                >
                  <i
                    class="fas fa-expand-alt"
                    style="transform: rotate(45deg)"
                  ></i>
                </MDBBtn>
              </div>
              <div class="mdb-col-3 mdb-p-0 mdb-ps-1">
                <MDBBtn
                  class="mdb-mt-0"
                  size="sm"
                  color="primary"
                  :iconClass="element.order !== 0 ? 'text-info' : 'grey-text'"
                  :disabled="element.order === 0"
                  @click="moveComponent(element.order, -1)"
                >
                  <i class="fas fa-chevron-up"></i>
                </MDBBtn>
              </div>
              <div class="mdb-col-3 mdb-p-0 mdb-ps-1">
                <MDBBtn
                  class="mdb-mt-0"
                  size="sm"
                  color="primary"
                  :iconClass="
                    element.order !== components.length - 1
                      ? 'text-info'
                      : 'grey-text'
                  "
                  :disabled="element.order === components.length - 1"
                  @click="moveComponent(element.order, 1)"
                >
                  <i class="fas fa-chevron-down"></i>
                </MDBBtn>
              </div>
              <div class="mdb-col-3 mdb-p-0 mdb-ps-1">
                <MDBBtn
                  class="mdb-mt-0"
                  size="sm"
                  color="danger"
                  @click="deleteComponent(element.order)"
                >
                  <i class="fas fa-trash"></i
                ></MDBBtn>
              </div>
            </div>
            <Block
              :html="element.html"
              :order="element.order"
              :config="element.config"
              draggable="false"
            ></Block>
          </div>
        </template>
      </draggable>
    </div>
  </div>
  <MDBBtn
    v-if="!fullscreen"
    color="secondary"
    size="lg"
    floating
    style="position: fixed; left: 25px; bottom: 85px; z-index: 1030"
    @click="clearProject"
  >
    <i class="fas fa-eraser"></i>
  </MDBBtn>
  <MDBBtn
    size="lg"
    v-else
    color="info"
    style="position: fixed; right: 80px; top: 80px; z-index: 1030"
    @click="exitFullscreen"
  >
    <i class="fas fa-compress fa-2x"></i>
  </MDBBtn>
</template>

<script>
import { useStore } from "vuex";
import { computed, nextTick, ref, watch } from "vue";
import Block from "@/components/Block.vue";
import MDBBtn from "@/components/mdb/MDBBtn.vue";
import draggable from "vuedraggable";
// import { initComponents } from "@/utils/initComponents";

export default {
  name: "Preview",
  components: {
    Block,
    draggable,
    MDBBtn,
  },
  setup() {
    const iframeRef = ref(null);

    const store = useStore();

    const mdbVersion = computed(() => store.getters.mdbVersion);
    const theme = computed(() => store.getters.theme);

    const userComponents = computed(() => store.getters.userComponents);
    //  vuedraggable pllugin accepts only mutable values to be passed
    // userComponents 'listens' to store changes and passes the data to components array
    const components = ref(userComponents.value);
    const showControls = ref(-1);

    const fullscreen = computed(() => store.getters.fullscreen);
    const exitFullscreen = () => {
      store.dispatch("setFullscreen");
    };

    watch(
      () => userComponents.value,
      (cur) => {
        components.value = cur;
        checkViewMode(display.value);
      },
    );

    const handleDragChange = () => {
      nextTick(() => {
        store.dispatch("sortComponentsIndexes", components.value);
      });
    };

    const moveComponent = (order, direction) => {
      store.dispatch("moveComponent", { order, direction });
    };

    const deleteComponent = (order) => {
      store.dispatch("deleteComponent", order);
    };

    const clearProject = () => {
      store.dispatch("clearProject");
    };

    const changeComponentSize = (order, size) => {
      store.dispatch("changeComponentSize", [order, size]);
    };

    const display = computed(() => store.getters.display);

    const iframeWrapperStyle = computed(() => {
      if (display.value !== "desktop") {
        return {
          width: `${
            devices[display.value].resX /
              devices[display.value].devicePixelRatio +
            7
          }px`,
          overflow: "hidden",
        };
      }

      return {};
    });

    const devices = {
      desktop: {
        resX: 1080,
        resY: 1920,
        devicePixelRatio: 3,
      },
      tablet: {
        resX: 1024,
        resY: 1366,
        devicePixelRatio: 2,
      },
      mobile: {
        resX: 640,
        resY: 980,
        devicePixelRatio: 2,
      },
    };

    const scaleModifier = 0.75;

    const isDesktop = ref(display.value === "desktop");

    watch(
      () => display.value,
      (cur) => {
        checkViewMode(cur);
      },
    );

    const checkViewMode = (value) => {
      if (value === "desktop") {
        isDesktop.value = true;
        return;
      }

      isDesktop.value = false;

      writeIframeContent();

      iframeRef.value.setAttribute(
        "width",
        devices[value].resX / devices[value].devicePixelRatio,
      );
      iframeRef.value.setAttribute(
        "height",
        devices[value].resY / devices[value].devicePixelRatio,
      );
      iframeRef.value.style.transform = `scale(${scaleModifier})`;
    };

    const writeIframeContent = () => {
      const contentHTML = store.getters.userComponents
        .map((component) => component.html)
        .join(",");

      const doc = iframeRef.value.contentWindow.document;
      doc.open();
      doc.write(
        `<html>
          <head>
            <title></title>
          </head>
          <body style="width: 100%; height: 100%; overflow: hidden;">
            <div style="width: 100%;
              height: 100%;
              overflow-y: scroll;
              padding: 0px 16px 0px 9px;
              box-sizing: content-box;">
              ${contentHTML}
            </div>
          </body>
        </html>`,
      );
      doc.close();

      appendScript(
        doc,
        `${process.env.VUE_APP_MDB_UI_KIT}/MDB5-STANDARD-UI-KIT-Free-${mdbVersion.value}/js/mdb.min.js`,
      );

      appendStylesheet(
        doc,
        "text/css",
        `${process.env.VUE_APP_MDB_UI_KIT}/MDB5-STANDARD-UI-KIT-Free-${mdbVersion.value}/css/themes/mdb.${theme.value}.min.css`,
      );
      appendStylesheet(
        doc,
        "",
        "https://use.fontawesome.com/releases/v5.15.1/css/all.css",
      );
      appendStylesheet(
        doc,
        "",
        "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
      );
    };

    const appendScript = (container, src) => {
      const newScript = document.createElement("script");
      newScript.id = "script";
      newScript.src = src;
      container.body.appendChild(newScript);
    };

    const appendStylesheet = (container, type, src) => {
      const link = document.createElement("link");
      link.href = src;
      link.rel = "stylesheet";
      link.type = type;
      container.head.appendChild(link);
    };

    const iframeStyle = computed(() => {
      return {
        borderRadius: "15px",
        border: "10px solid #333333",
        boxSizing: "content-box",
        borderTop: "80px solid #333333",
        borderBottom: "80px solid #333333",
        overflowX: "hidden",
        transformOrigin: "center center",
        willChange: "transform",
        margin: "0 auto",
        overflow: "hidden",
      };
    });

    return {
      fullscreen,
      exitFullscreen,
      isDesktop,
      iframeWrapperStyle,
      iframeRef,
      iframeStyle,
      showControls,
      handleDragChange,
      components,
      clearProject,
      deleteComponent,
      moveComponent,
      changeComponentSize,
    };
  },
};
</script>

<style></style>
