<template>
  <h4>Edit</h4>
  <hr class="mdb-my-4" />

  <div class="mdb-pb-4">
    <MDBBtn
      v-for="(color, key) in colors"
      :key="key"
      :data-builder-color="color"
      size="sm"
      floating
      class="mdb-m-1"
      :class="`btn-${color}`"
      @click="changeColor(color)"
    ></MDBBtn>
  </div>

  <div class="mdb-pb-4">
    <MDBInput
      v-model="textInput"
      label="Button text"
      @update:modelValue="updateText"
    />
  </div>

  <div class="mdb-pb-4">
    <MDBInput
      v-model="hrefInput"
      label="Button href"
      @update:modelValue="updateHref"
    />
  </div>

  <div class="mdb-pb-4 d-flex mdb-align-content-center">
    <div class="mdb-flex-fill">
      <MDBInput
        v-model="iconInput"
        label="Search icons"
        @update:modelValue="filterIcons"
      />
    </div>

    <a
      v-if="blockConfig[configName]?.icon"
      style="color: #dd4b39"
      href="#!"
      role="button"
      class="mdb-d-flex mdb-align-items-center"
      @click.prevent="removeIcon"
    >
      <i class="fas fa-times"></i
    ></a>
  </div>

  <MDBSideNavMenu scrollContainer class="mt-2" v-if="filteredIcons?.length">
    <i
      v-for="(icon, key) in filteredIcons"
      :class="[icon, 'mdb-text-dark']"
      :key="key"
      @click="updateIcon(icon)"
    ></i>
  </MDBSideNavMenu>
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import MDBSideNavMenu from "@/components/mdb/MDBSideNavMenu";
import MDBBtn from "@/components/mdb/MDBBtn";
import colors from "../../utils/colors.json";
import faBasic from "../../utils/fa.basic.json";
import { searchIcons, removeIconClasses } from "./pickersUtils";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput, MDBBtn, MDBSideNavMenu },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref("");

    const iconInput = ref("");
    const filteredIcons = ref([]);

    const filterIcons = (query) => {
      filteredIcons.value = searchIcons(query);
    };

    const updateIcon = (icon) => {
      const iconElement = element.value.querySelector("i");

      let restClasses = ["p-1", "ps-2"];

      if (iconElement) {
        removeIconClasses(iconElement);

        restClasses = [...iconElement.classList].map((className) => className);

        iconElement.parentElement.removeChild(iconElement);
      }

      const newIcon = document.createElement("i");
      newIcon.className = icon;

      newIcon.classList.add(...restClasses);

      element.value.appendChild(newIcon);

      blockConfig.value[configName.value].icon = icon;
      updateConfig.value();
    };

    const removeIcon = () => {
      const iconElement = element.value.querySelector("i");

      iconElement.parentElement.removeChild(iconElement);

      blockConfig.value[configName.value].icon = null;
      updateConfig.value();
    };

    const textInput = ref("");

    const updateText = (text) => {
      blockConfig.value[configName.value].text = text;
      element.value.innerText = text;
      element.value.style.minWidth = "auto";

      if (blockConfig.value[configName.value].icon) {
        const icon = document.createElement("i");
        icon.classList.add(
          ...blockConfig.value[configName.value].icon.split(" "),
        );
        icon.classList.add("p-1");

        element.value.appendChild(icon);
      }
      updateConfig.value();
    };

    const hrefInput = ref("");
    const updateHref = (href) => {
      blockConfig.value[configName.value].href = href;
      element.value.href = href;
      updateConfig.value();
    };

    const changeColor = (color) => {
      let colorMatch = [...element.value.classList].find((className) => {
        return colors.filter((color) => className.includes(color)).length;
      });

      if (colorMatch) {
        element.value.className = element.value.className.replace(
          colorMatch,
          ` btn-${color}`,
        );
      } else {
        element.value.classList.add(`btn-${color}`);
      }

      blockConfig.value[configName.value].color = color;
      updateConfig.value();
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          textInput.value = "";
          iconInput.value = "";
          filteredIcons.value = [];
        }
      },
    );

    onMounted(() => {
      configName.value = element.value.dataset["builderName"];
      textInput.value = blockConfig.value[configName.value].text;
      hrefInput.value = blockConfig.value[configName.value].href;
    });

    return {
      textInput,
      updateText,
      filteredIcons,
      filterIcons,
      updateIcon,
      removeIcon,
      iconInput,
      hrefInput,
      updateHref,
      faBasic,
      colors,
      changeColor,
      configName,
      blockConfig,
    };
  },
};
</script>
