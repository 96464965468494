<template>
  <MDBScrollbar
    v-if="scrollContainer"
    :height="scrollElementHeight"
    suppressScrollX
    class="mdb-position-relative"
  >
    <component :class="className" :is="tag" v-bind="attrs" ref="sidenavMenu">
      <slot />
    </component>
  </MDBScrollbar>
  <component
    v-else
    :class="className"
    :is="tag"
    v-bind="attrs"
    ref="sidenavMenu"
  >
    <slot />
  </component>
</template>

<script>
import { computed, onMounted, provide, reactive, ref } from "vue";
import MDBScrollbar from "./MDBScrollbar";

export default {
  name: "MDBSideNavMenu",
  components: {
    MDBScrollbar,
  },
  props: {
    tag: {
      type: String,
      default: "ul",
    },
    classes: String,
    accordion: {
      type: Boolean,
      default: false,
    },
    scrollContainer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const className = computed(() => {
      return ["mdb-sidenav-menu", props.classes];
    });

    const accordionState = reactive({
      itemsCount: 0,
      active: null,
    });

    const incrementAccordionItemsCount = () => {
      accordionState.itemsCount++;
      return accordionState.itemsCount;
    };

    const setAccordionActiveItem = (item) => {
      accordionState.active = item;
    };

    provide("accordionState", props.accordion ? accordionState : null);
    provide("incrementAccordionItemsCount", incrementAccordionItemsCount);
    provide("setAccordionActiveItem", setAccordionActiveItem);

    onMounted(() => {
      if (props.scrollContainer) {
        setupScrollContent();
      }
    });

    const sidenavMenu = ref("sidenavMenu");
    const scrollElementHeight = ref("100%");

    const outerHeight = (element) => {
    const height = element.offsetHeight,
        style = window.getComputedStyle(element)

    return ['top', 'bottom']
        .map(side => parseInt(style[`margin-${side}`]))
        .reduce((total, side) => total + side, height)
}

    const setupScrollContent = () => {
      const scrollElement = sidenavMenu.value;
      const pickerContent = document.querySelector('.mdb-picker-content')

      const siblings = Array.from(
        pickerContent.children,
      ).filter((el) => el !== scrollElement.parentNode);

      const siblingsHeight = siblings.reduce((a, b) => {
        return a + outerHeight(b);
      }, 0);

      scrollElementHeight.value = `calc(${pickerContent.clientHeight}px - ${siblingsHeight}px)`;
    };

    return {
      className,
      sidenavMenu,
      scrollElementHeight,
      props,
      attrs,
    };
  },
};
</script>
