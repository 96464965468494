<template>
  <h4>Edit icons</h4>
  <hr class="mdb-my-4" />

  <div>
    <i
      v-for="(icon, key) in faBasic"
      :class="icon"
      :key="key"
      @click="updateIcon(icon)"
    ></i>
  </div>

  <hr class="mdb-my-4 mdb-text-dark" />

  <div class="mdb-pb-4">
    <MDBInput
      v-model="input"
      label="Search icons"
      placeholder="Search icons"
      @update:modelValue="filterIcons"
    />
  </div>
  <MDBSideNavMenu scrollContainer class="mt-2" v-if="filteredIcons?.length">
    <i
      v-for="(icon, key) in filteredIcons"
      :class="[icon, 'mdb-text-dark']"
      :key="key"
      @click="updateIcon(icon)"
    ></i>
  </MDBSideNavMenu>
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import MDBSideNavMenu from "@/components/mdb/MDBSideNavMenu";
import faBasic from "../../utils/fa.basic.json";
import { searchIcons, removeIconClasses } from "./pickersUtils";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput, MDBSideNavMenu },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref("");

    const input = ref("");
    const filteredIcons = ref([]);

    const filterIcons = (e) => {
      filteredIcons.value = searchIcons(e);
    };

    const updateIcon = (icon) => {
      removeIconClasses(element.value);
      element.value.className = `${icon} ${element.value.className}`;

      blockConfig.value[configName.value] = element.value.className;
      updateConfig.value();
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          input.value = "";
          filteredIcons.value = [];
        }
      },
    );

    onMounted(() => {
      configName.value = element.value.dataset["builderName"];
    });

    return {
      filteredIcons,
      filterIcons,
      updateIcon,
      input,
      faBasic,
    };
  },
};
</script>
