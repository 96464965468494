<template>
  <div v-if="isDraggedFirstTop" class="mdb-placeholder" />
  <section
    v-html="html"
    ref="block"
    @dragover.stop="handleDrag"
    v-bind="$attrs"
    :class="sectionClassName"
  ></section>
  <div v-if="isDragged" class="mdb-placeholder" />
</template>

<script>
import { ref, onMounted, onUpdated, computed, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { appendConfig } from "../utils/appendConfig";

export default {
  name: "Block",
  props: {
    html: {
      templateHtml: true,
      type: String,
    },
    order: Number,
    config: Object,
  },
  setup(props) {
    const store = useStore();

    const block = ref(null);
    const blockConfig = ref({ ...props.config });

    watch(
      () => props.config,
      (config) => {
        blockConfig.value.container = config.container;
        blockConfig.value.paddingTop = config.paddingTop;
      },
      { deep: true },
    );

    const updateConfig = () => {
      store.dispatch("updateUserComponentConfig", [
        props.order,
        blockConfig.value,
      ]);
    };

    const sectionClassName = computed(() => {
      return [
        blockConfig.value.container === "none"
          ? "overflow-hidden"
          : "container",
        blockConfig.value.paddingTop === 0 ? "pt-0" : "pt-5",
      ];
    });

    const display = computed(() => store.getters.display);

    const isDraggedFirstTop = ref(false);

    const isDragged = computed(
      () => store.getters.dropIndex === props.order + 1,
    );

    const handleDrag = (e) => {
      if (!store.getters.draggedComponent || display.value !== "desktop") {
        return;
      }
      const { x, width } = e.target.getBoundingClientRect();

      if (e.x < x + 50 || e.x > x + width - 50) {
        store.dispatch("drop", -1);
      } else {
        if (props.order === 0) {
          const { y, height } = block.value.getBoundingClientRect();

          if (e.y < y + height / 2) {
            store.dispatch("drop", 0);

            isDraggedFirstTop.value = true;

            return;
          }
        }

        isDraggedFirstTop.value = false;

        store.dispatch("drop", props.order + 1);
      }
    };

    const removeListeners = (element) => {
      element.removeEventListener("click", setEditableElementConfig);
      element.removeEventListener("blur", setContentEditableConfig);
    };

    const setEditableElementConfig = (e) => {
      e.preventDefault();
      let element = e.target;

      if (!element.dataset["builderEdit"]) {
        // if selected element is a form element use its handler
        if (element.nodeName === "INPUT" || element.closest(".form-outline")) {
          handleInputFocus(e);
          return;
        }

        // else find closest editable element
        element = element.closest("[data-builder-edit]");
      }

      if (
        (element.dataset["builderEdit"] === "text" &&
          element.dataset["builderHref"] === undefined) ||
        null
      ) {
        return;
      }

      store.dispatch("setPicker", element.dataset["builderEdit"]);
      store.dispatch("setPickerConfig", {
        element,
        blockConfig,
        updateConfig,
      });
    };

    const setContentEditableConfig = (e) => {
      e.preventDefault();
      let element = e.target;

      if (!element.dataset["builderEdit"]) {
        element = element.closest("data-builder-edit]");
      }

      const configName = element.dataset["builderName"];
      blockConfig.value[configName] = e.target.innerText;
      updateConfig();
    };

    const setEditableElements = () => {
      const blockRef = block.value;
      nextTick(() => {
        appendConfig(blockRef, blockConfig);
        const editableElements = blockRef.querySelectorAll(
          "[data-builder-edit]",
        );
        // clear event listener in case of elements update
        editableElements.forEach((editableElement) => {
          removeListeners(editableElement);
        });

        // set proper event listeners for element config udpate
        editableElements.forEach((editableElement) => {
          if (editableElement.dataset["builderEdit"] === "text") {
            editableElement.contentEditable = true;

            editableElement.addEventListener("blur", setContentEditableConfig);
            if (editableElement.dataset["builderHref"] === undefined) {
              return;
            }
          }

          // aria-controls allows the sidenav to be closed via 'escape' key
          editableElement.setAttribute("aria-controls", "#picker-editor");
          editableElement.addEventListener("click", setEditableElementConfig);
        });
      });
    };

    const inputElements = ref(null);

    const handleInputFocus = (event) => {
      event.preventDefault();
      const formOutline = event.target.closest(".form-outline");

      if (formOutline) {
        if (formOutline.querySelector(".select-input")) {
          handleSelectFocus(event);
          return;
        }

        formOutline.querySelector("label").focus();
      } else {
        event.target.parentNode.querySelector("label").focus();
      }
    };

    const handleSelectFocus = (event) => {
      event.preventDefault();

      const element = event.target
        .closest(".form-outline")
        .parentNode.querySelector("select");

      store.dispatch("setPicker", "select");
      store.dispatch("setPickerConfig", {
        element,
        blockConfig,
        updateConfig,
      });
    };

    onMounted(() => {
      setEditableElements();

      inputElements.value = block.value.querySelectorAll("input, textarea");

      inputElements.value.forEach((element) => {
        element.addEventListener("click", handleInputFocus);
        element.addEventListener("focus", handleInputFocus);
      });
    });

    onUpdated(() => {
      setEditableElements();

      inputElements.value = block.value.querySelectorAll("input, textarea");

      inputElements.value.forEach((element) => {
        element.addEventListener("click", handleInputFocus);
        element.addEventListener("focus", handleInputFocus);
      });
    });

    return {
      isDragged,
      isDraggedFirstTop,
      handleDrag,
      block,
      sectionClassName,
    };
  },
};
</script>

<style scoped>
.mdb-placeholder {
  height: 100px;
  outline: 2px dashed #33b5e5;
  margin-bottom: 2rem;
}
</style>
