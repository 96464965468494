<template>
  <h4>Edit image</h4>
  <hr class="mdb-my-4" />
  <div class="mdb-mb-4">
    <MDBInput
      v-model="inputs.src"
      label="Image source"
      @blur="updateValue('src')"
      @keydown.enter="updateValue('src')"
    />
  </div>
  <div class="mdb-mb-4">
    <MDBInput
      v-model="inputs.alt"
      label="Image alt"
      @update:modelValue="updateValue('alt')"
      @keydown.enter="updateValue('alt')"
    />
  </div>

  <hr class="mdb-mt-0 mdb-text-dark" />

  <SearchImages :updateFn="updateSrc" />
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import SearchImages from "@/components/pickers/SearchImages";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput, SearchImages },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref("");

    const isContentLoaded = ref(false);

    const inputs = reactive({
      src: "",
      alt: "",
    });

    const updateValue = (type) => {
      blockConfig.value[configName.value][type] = inputs[type];
      element.value[type] = inputs[type];
      updateConfig.value();
    };

    const updateSrc = (src) => {
      inputs.src = src;
      updateValue("src");
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          inputs.src = "";
          inputs.alt = "";
          inputs.query = "";
          inputs.error = "";
        } else {
          nextTick(() => {
            isContentLoaded.value = true;
          });
        }
      },
    );

    onMounted(() => {
      configName.value = element.value.dataset["builderName"];
      inputs.src = blockConfig.value[configName.value].src;
      inputs.alt = blockConfig.value[configName.value].alt;
      isContentLoaded.value = true;
    });

    return {
      inputs,
      updateValue,
      isContentLoaded,
      configName,
      blockConfig,
      updateSrc,
    };
  },
};
</script>
