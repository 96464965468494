<template>
  <MDBTabs v-model="activeTab">
    <!-- Tabs navs -->
    <MDBTabNav
      pills
      tabsClasses="mdb-mb-3"
      class="mdb-mb-5 mdb-pt-4 mdb-px-4 mdb-bg-white mdb-sticky-top"
    >
      <MDBTabItem
        v-for="(category, key) in templateCategories"
        :key="key"
        :tabId="category.id"
        :href="category.id"
        anchorClass="mdb-py-2 mdb-p-3"
        >{{ category.name }}</MDBTabItem
      >
    </MDBTabNav>
    <!-- Tabs navs -->
    <!-- Tabs content -->
    <MDBTabContent class="mdb-mb-5 mdb-px-4 mdb-pb-4 mdb-position-relative">
      <MDBTabPane
        v-for="(category, key) in templateCategories"
        :key="key"
        :tabId="category.id"
      >
        <div
          v-for="(component, key) in templateComponents"
          :key="key"
          draggable="true"
          style="cursor: pointer"
          @dragstart="handleDrag($event, component)"
          @dragend="dragEnd(component.id)"
        >
          <div
            v-if="
              category.name.toLowerCase() === 'all' ||
              component.category.toLowerCase() === category.name.toLowerCase()
            "
            class="
              mdb-bg-image
              mdb-hover-overlay
              mdb-ripple
              mdb-shadow-4-custom
              mdb-border
              mdb-p-2
              mdb-rounded-5
              mdb-mb-5
            "
            v-mdb-ripple="{ color: 'primary' }"
            @click="addUserComponent(component.id)"
          >
            <img :src="component.img" class="mdb-w-100" />
            <a href="#!" @click.prevent>
              <div
                class="mdb-mask"
                style="background-color: rgba(134, 169, 235, 0.2)"
              ></div>
            </a>
          </div>
        </div>
      </MDBTabPane>
    </MDBTabContent>
    <!-- Tabs content -->
  </MDBTabs>
</template>

<script>
import MDBTabs from "@/components/mdb/MDBTabs";
import MDBTabNav from "@/components/mdb/MDBTabNav";
import MDBTabItem from "@/components/mdb/MDBTabItem";
import MDBTabContent from "@/components/mdb/MDBTabContent";
import MDBTabPane from "@/components/mdb/MDBTabPane";
import mdbRipple from "@/directives/mdbRipple";

import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "List",
  components: {
    MDBTabs,
    MDBTabNav,
    MDBTabItem,
    MDBTabContent,
    MDBTabPane,
  },
  directives: { mdbRipple },
  setup() {
    const activeTab = ref("tab-all");
    const store = useStore();
    const templateComponents = computed(() => store.getters.templateComponents);
    const templateCategories = computed(() => store.getters.templateCategories);
    const listEl = ref(null);

    const addUserComponent = (id) => {
      store.dispatch("addUserComponent", id);
    };

    const display = computed(() => store.getters.display);

    const handleDrag = (e, component) => {
      e.dataTransfer.setData("plain/text", component);
      store.dispatch("startDrag", component);
    };

    const dragEnd = (id) => {
      if (display.value !== "desktop") {
        return;
      }
      store.dispatch("endDrag", id);
    };

    onMounted(() => {
      listEl.value = document.getElementById("mdb-blocks-list");
    });

    watch(
      () => activeTab.value,
      () => {
        if (listEl.value) {
          listEl.value.scrollTop = 0;
        }
      },
      { deep: true },
    );

    return {
      handleDrag,
      dragEnd,
      activeTab,
      templateComponents,
      templateCategories,
      addUserComponent,
    };
  },
};
</script>
