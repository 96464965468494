<template>
  <h4>Select options Edit</h4>
  <hr class="mdb-my-4" />

  <div class="mdb-pb-4" v-for="(input, key) in inputs" :key="key">
    <MDBInput
      v-model="input.value"
      :label="`Option ${key}`"
      @update:modelValue="(e) => updateValue(e, input, key)"
    />
  </div>
</template>

<script>
import MDBInput from "@/components/mdb/MDBInput";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: { MDBInput },
  props: {
    modelValue: Boolean,
  },
  setup(props) {
    const store = useStore();
    const blockConfig = computed(() => store.getters.blockConfig);
    const updateConfig = computed(() => store.getters.updateConfig);
    const element = computed(() => store.getters.element);
    const configName = ref("");

    const inputs = ref([]);

    const originalOptions = ref(null);
    const visibleOptions = ref(null);

    const updateValue = (value, input, key) => {
      blockConfig.value[input.builderName] = value;
      [...originalOptions.value][key].innerText = value;
      [...visibleOptions.value][key].innerText = value;
      updateConfig.value();
    };

    watch(
      () => props.modelValue,
      (cur) => {
        if (!cur) {
          inputs.value = [];
        }
      },
    );

    onMounted(() => {
      originalOptions.value = element.value.querySelectorAll("option");

      visibleOptions.value = document.body.querySelectorAll(
        ".select-options-list  span",
      );
      configName.value = element.value.dataset["builderHref"];
      [...originalOptions.value].forEach((option) => {
        const input = {
          value: option.innerText,
          ...option.dataset,
        };

        inputs.value.push(input);
      });
    });

    return {
      inputs,
      updateValue,
      configName,
      blockConfig,
    };
  },
};
</script>
